<template>
  <div class="select-goods">
    <el-dialog
      :visible="openTicket"
      title="选择优惠券"
      :before-close="close"
      v-dialogDrag="true"
      width="70%"
    >
      <div class="x-start">
        <div class="left y-start">
          <el-input
            v-model="categoryName"
            placeholder="请输入类别名称"
            clearable
            size="small"
            prefix-icon="el-icon-search"
            style="margin-bottom: 20px"
          />
          <el-scrollbar class="scrollbarTree">
            <el-tree
              node-key="id"
              :data="categoryTreeOptions"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              ref="tree"
              default-expand-all
              highlight-current
              @node-click="handleNodeClick"
            />
          </el-scrollbar>
        </div>
        <div class="right marL15 y-start">
          <div class="x-f">
            <el-form
              :model="queryParams"
              ref="queryForm"
              size="small"
              :inline="true"
              v-show="showSearch"
              label-width="68px"
              @submit.native.prevent
            >
              <el-form-item label="" prop="categoryName">
                <el-input
                  v-model="queryParams.queryCode"
                  placeholder="请输入优惠券名称"
                  clearable
                  style="width: 240px"
                  @keyup.enter.native="handleQuery"
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  size="mini"
                  @click="handleQuery"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-table
              v-loading="loading"
              :data="ticketList"
              @selection-change="handleSelectionChange"
              border
              height="500"
              max-height="500"
              ref="goodsTable"
              row-key="goodsId"
            >
              <el-table-column
                type="selection"
                width="50"
                align="center"
                :reserve-selection="true"
              />
              <el-table-column
                label="优惠券名称"
                width="60"
                align="center"
                key="sortNo"
                prop="sortNo"
              />
              <el-table-column
                label="优惠券类型"
                width="60"
                align="center"
                key="sortNo"
                prop="sortNo"
              />
              <el-table-column
                label="优惠券面额"
                width="60"
                align="center"
                key="sortNo"
                prop="sortNo"
              />
              <el-table-column
                label="生效时间"
                width="60"
                align="center"
                key="sortNo"
                prop="sortNo"
              />
              <el-table-column
                label="失效时间"
                width="60"
                align="center"
                key="sortNo"
                prop="sortNo"
              />
              <el-table-column
                label="备注"
                width="60"
                align="center"
                key="sortNo"
                prop="sortNo"
              />
              <el-table-column
                label="创建人"
                width="60"
                align="center"
                key="sortNo"
                prop="sortNo"
              />
              <el-table-column
                label="创建时间"
                width="60"
                align="center"
                key="sortNo"
                prop="sortNo"
              >
                <template slot-scope="scope">
                  <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="修改人"
                width="60"
                align="center"
                key="sortNo"
                prop="sortNo"
              />
              <el-table-column
                label="修改时间"
                width="60"
                align="center"
                key="sortNo"
                prop="sortNo"
              >
                <template slot-scope="scope">
                  <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="tag" @click="showPitchGoods" ref="tag">
              <i class="el-icon-arrow-left" v-show="!openGoods"></i
              ><i class="el-icon-arrow-right" v-show="openGoods"></i>
              <div class="right">
                <div>已</div>
                <div>选</div>
                <span>{{ ids.length }}</span>
              </div>
            </div>
            <div class="pitch-goods" v-show="openGoods">
              <div class="pitch-title">
                <div class="left">选中的商品</div>
                <div class="right" @click="delPitchGoods('#')">清空</div>
              </div>
              <el-scrollbar class="scrollbarPitch">
                <div class="itemList">
                  <div
                    class="item-goods"
                    v-for="item in selectAllData"
                    :key="item.goodsId"
                  >
                    <div class="name">{{ item.goodsName }}</div>
                    <div
                      class="el-icon-close"
                      @click="delPitchGoods(item)"
                    ></div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
          <div class="goods-footer">
            <div class="left">
              <span class="select"
                >已选<span class="num">{{ this.ids.length }}</span
                >条</span
              >
              <span class="checkAll" v-show="!isCheckAll" @click="selectAll"
                >选择全部</span
              >
              <span class="checkAll" v-show="isCheckAll" @click="selectAll"
                >取消选择</span
              >
            </div>
            <!-- 分页 -->
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getList"
            />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="getTicket">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  // 选择优惠券
  name: 'index',
  dicts: [],
  props: {
    //商品信息开关
    openTicket: {
      type: Boolean,
      default: false
    },
    queryCode: {
      type: String
    }
  },
  data () {
    return {
      //遮罩层
      loading: false,
      //分类名称搜索
      categoryName: '',
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 当前页商品表格数据
      ticketList: null,
      //所有商品数据
      goodsAllList: null,
      //选中的商品数据
      selectAllData: null,
      // 分类树选项
      categoryTreeOptions: null,
      //判断当前是否全选数据
      isCheckAll: false,
      //是否开启选中商品框
      openGoods: false,
      //定位已选图标的位置
      posiRight: '52px',
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        queryCode: undefined
      }
    }
  },
  watch: {
    // 根据名称筛选分类树
    categoryName (val) {
      this.$refs.tree.filter(val)
    },
    queryCode (val) {
      this.queryParams.queryCode = val
      this.handleQuery()
    },
    OpenTicket (val) {
      if (!val) this.reset()
    }
  },
  created () {
    this.getList()
    this.getTreeselect()
  },
  methods: {
    //关闭对话框
    close () {
      this.$emit('closeDia', false)
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      // this.selectAllData = selection
      // this.ids = selection.map(item => item.goodsId)
    },
    //选中商品
    getGoods () {
      if (this.ids.length >= 1) this.$emit('getGoodS', this.selectAllData)
      this.close()
      this.delPitchGoods('#')
    },
    /** 查询商品列表 */
    getList () {
      this.loading = true
    },
    //下拉树结构数据转换
    normalizer (node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },
    //显示选中的商品
    showPitchGoods () {
      this.span = this.span === 20 ? 16 : this.span
      this.openGoods = !this.openGoods
      this.posiRight = this.posiRight === '52px' ? '252px' : '52px'
      this.$refs.tag.style.right = this.posiRight
    },

    // ---------------------------

    /** 查询分类下拉树结构 */
    getTreeselect () {},

    // 筛选节点
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },

    // 节点单击事件
    handleNodeClick (data) {
      console.log(data)
      this.queryParams.categoryId = data.id
      this.handleQuery()
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //选中商品
    getTicket () {
      if (this.ids.length >= 1) this.$emit('getTicket', this.selectAllData)
      this.close()
      this.delPitchGoods('#')
    },
    //清空搜索框
    reset () {
      this.categoryName = ''
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        queryCode: undefined
      }
      this.handleQuery()
    },

    //点击全选所有数据
    selectAll () {
      this.isCheckAll = !this.isCheckAll
      if (this.isCheckAll) {
        this.goodsAllList.map(row => {
          for (let i = 0; i < this.selectAllData.length; i++) {
            if (row.goodsId === this.selectAllData[i].goodsId) return
          }
          this.$nextTick(() => {
            this.$refs.goodsTable.toggleRowSelection(row, this.isCheckAll) //全选
          })
        })
      } else {
        this.$nextTick(() => {
          this.$refs.goodsTable.clearSelection()
        })
      }
      this.getList()
    },
    //取消选中的商品
    delPitchGoods (row) {
      if (row === '#') {
        this.$nextTick(() => {
          this.$refs.goodsTable.clearSelection() //清空选中
        })
      } else {
        this.goodsList.map(item => {
          if (item.goodsId === row.goodsId) {
            this.$nextTick(() => {
              this.$refs.goodsTable.toggleRowSelection(item) //取消行选中
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-goods {
  .left {
    width: 230px;

    .treeBox {
    }
  }

  .right {
    width: calc(100% - 245px);
  }

  //选中的图标
  .tag {
    position: absolute;
    transform: translateY(-50%);
    right: 52px;
    top: 50%;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    width: 50px;
    border: 1px solid #dfe6ec;
    border-radius: 5px;
    align-items: center;
    z-index: 9999;
    background-color: #fff;
    .right {
      display: flex;
      flex-direction: column;
      padding: 0 2px;
      text-align: center;
    }
    span {
      padding: 0 6px;
      text-align: center;
      line-height: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #2386ee;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>

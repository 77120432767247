<template>
  <c-dialog
    title="导入错误信息"
    :width="700"
    :showDialog.sync="errVisible"
    :levelType="true"
    @close="close"
  >
    <template #content>
      <el-table
        :data="errorList"
        tooltip-effect="dark"
        border
        ref="multipleTable"
        max-height="400"
        height="400"
        style="width: 100%"
      >
        <el-table-column align="center" type="index" width="80" label="序号">
        </el-table-column>
        <el-table-column
          prop="errorInfo"
          label="错误信息"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
      </el-table>
    </template>
  </c-dialog>
</template>

<script>
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
export default {
  name: 'importError',
  components: {
    CDialog
  },
  props: {
    //错误信息开关
    errVisible: {
      type: Boolean,
      default: false
    },
    //错误信息
    errorList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: {},
  methods: {
    close () {
      this.$emit('update:errVisible', false)
    }
  }
}
</script>

<style></style>

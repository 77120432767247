<template>
  <!-- 顶部定位区域 页眉 -->
  <div class="content x-bc">
    <!-- 没使用左边插槽就不显示 -->
    <div class="left">
      <slot name="left" v-if="$slots.left"></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadTool'
}
</script>
<style lang="scss" scoped>
//.head-tool {
//    width:
//    box-sizing: border-box;

.content {
  //background-color: red;
  width: calc(100% - 150px);
  height: 40px;
  box-sizing: border-box;
  background-color: #fff;
  position: fixed;
  margin-top: 0;
  z-index: 999;
  // box-shadow: 3px 10px 5px #ededf1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   padding: 0 30px;

  .left {
    .headTitle {
      //        margin: 0;
      color: #000;
      font-size: 20px;
      font-weight: 700;
      //        height: 60px;
      //        line-height: 60px;
    }
  }
  //
  .right {
    //flex: 1;
    //text-align: right;

    .el-dropdown {
      margin: 0 10px;
    }
  }
}
//}
</style>

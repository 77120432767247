<template>
  <i class="iconfont" :class="name" :style="{ color: color, fontSize: size }"></i>
</template>

<script>
export default {
  name: "iconParkALi",
  props: {
    //名称
    name: {
      type: String,
      default: "",
    },
    //颜色
    color: {
      type: String,
      default: "",
    },
    //大小
    size: {
      type: String || Number,
      default: "",
    },
  },
};
</script>

<style></style>

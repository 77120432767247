var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("c-dialog", {
    attrs: {
      title: "导入错误信息",
      width: 700,
      showDialog: _vm.errVisible,
      levelType: true,
    },
    on: {
      "update:showDialog": function ($event) {
        _vm.errVisible = $event
      },
      "update:show-dialog": function ($event) {
        _vm.errVisible = $event
      },
      close: _vm.close,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.errorList,
                  "tooltip-effect": "dark",
                  border: "",
                  "max-height": "400",
                  height: "400",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    type: "index",
                    width: "80",
                    label: "序号",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "errorInfo",
                    label: "错误信息",
                    "show-overflow-tooltip": "",
                    align: "center",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content x-bc" }, [
    _c(
      "div",
      { staticClass: "left" },
      [_vm.$slots.left ? _vm._t("left") : _vm._e()],
      2
    ),
    _c("div", { staticClass: "right" }, [_vm._t("right")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <div id="map"></div>
        <!--        <img src="@/assets/images/hot.png">-->
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'index',
    props: {
        location: {
            type: Object,
            default: {}
        },
        range: {
            // 范围 半径
            type: [Number, String],
            default: 0
        }
    },
    watch: {
        location() {
            this.addImgMarker()
        },
        range() {
            this.addImgMarker()
        },
        '$route': 'init'
    },
    data() {
        return {
            center: null,
            map: null,
            // latitude: 22.56323, //39.908769,
            // longitude: 113.889256
            // imgurl: ''
        }
    },
    mounted() {
        // this.requestQQ('address')
        this.initMap()
    },
    methods: {
        initMap() {
            this.center = new TMap.LatLng(this.location.latitude, this.location.longitude)
            console.log('中心点：', this.center)
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            this.map = new TMap.Map(document.getElementById('map'), {
                center: this.center,//设置地图中心点坐标
                zoom: 13,   //设置地图缩放级别
                // pitch: 10,  //设置俯仰角
                src: require('@/assets/images/location.png'), // 自定义的标记图标
                rotation: 45    //设置地图旋转角度
            });

        },
        addImgMarker(data) {
            this.center = new TMap.LatLng(this.location.latitude, this.location.longitude)
            this.map.setCenter(this.center);
            try {
                if (this.markerLayer) {
                    this.markerLayer.updateGeometries([
                        {
                            "styleId": "myStyle",
                            "id": "0",
                            "position": new TMap.LatLng(this.location.latitude, this.location.longitude),
                        }
                    ])
                    this.multiCircle.updateGeometries([
                            {
                                id: '01',
                                styleId: 'circle',
                                center: this.center, //圆形中心点坐标 
                                radius: Number(this.range) * 1000,	//半径（单位：米）
                            }
                        ])

                } else {
                    this.markerLayer = new TMap.MultiMarker({
                        map: this.map,
                        styles: {
                            myStyle: new TMap.MarkerStyle({
                                width: 25, // 点标记样式宽度（像素）
                                height: 30, // 点标记样式高度（像素）
                                src: require('@/assets/images/location.png'), // 自定义的标记图标
                                anchor: { x: 16, y: 32 }
                            })
                        },
                        geometries: [
                            {
                                id: '0',
                                styleId: 'myStyle',
                                position: new TMap.LatLng(this.location.latitude, this.location.longitude)
                            }
                        ]
                    })
                    // 画圆
                    this.multiCircle = new TMap.MultiCircle({
                            map: this.map,
                            styles: { // 设置圆形样式
                                'circle': new TMap.CircleStyle({
                                    'color': 'rgba(41,91,255,0.16)',
                                    'showBorder': true,
                                    'borderColor': 'rgba(41,91,255,1)',
                                    'borderWidth': 2,
                                }),
                            },
                            geometries: [{
                                id: '01',
                                styleId: 'circle',
                                center: this.center, //圆形中心点坐标 
                                radius: Number(this.range) * 1000,	//半径（单位：米）
                            }]
                        });

                }
            } catch (error) {

            }

        },

    }
}
</script>

<style scoped>
#map {
    width: 1300px;
    height: 600px;
    cursor: pointer;
}
</style>
